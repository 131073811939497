import DescribedLabel from '~/src/components/utils/form/DecribedLabel'
import { Box, FormControlLabel } from '@mui/material'
import Toggle from '~/src/components/mui-wrappers/styled/toggle'
import { ChangeEvent } from 'react'
import PromotionsSwitchLabel from '~/src/components/profile/account/PromotionsSwitchLabel'
import NewsLetterSwitchLabel from '~/src/components/profile/account/NewsLetterSwitchLabel'
import { MarketingPreferencesForm } from '~/src/components/profile/marketing-preferences/index'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  marketingPreferences: MarketingPreferencesForm,
  onChange(event: ChangeEvent<HTMLInputElement>): void,
}

const MarketingPreferencesFields = ({ marketingPreferences, onChange }: Props) => {
  const { t } = useTranslations()

  return (
    <>
      <DescribedLabel
        label={t('profile.marketing.promotions')}
        description={t('profile.marketing.promotionsDescription')}
        gridArea='promotions'
      />
      <Box gridArea='promotions-toggle'>
        <FormControlLabel
          name='receivePromotions'
          control={
            <Toggle
              checked={marketingPreferences.receivePromotions}
              onChange={onChange}
            />
          }
          label={<PromotionsSwitchLabel />}
        />
      </Box>
      <DescribedLabel
        label={t('profile.marketing.newsletters')}
        description={t('profile.marketing.newslettersDescription')}
        gridArea='newsletters'
      />
      <Box gridArea='newsletters-toggle'>
        <FormControlLabel
          name='receiveNewsletter'
          control={
            <Toggle
              checked={marketingPreferences.receiveNewsletter}
              onChange={onChange}
            />
          }
          label={<NewsLetterSwitchLabel />}
        />
      </Box>
    </>
  )
}

export default MarketingPreferencesFields

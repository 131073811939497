import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { BRAND_STATE } from '~/src/stores/brand'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { IS_ON_MARKETPLACE_PAGE_SELECTOR } from '~/src/stores/layout'
import { LANGUAGE_STATE } from '~/src/stores/lang'

const NewsLetterSwitchLabel = () => {
  const { t } = useTranslations()
  const language = useEoValue(LANGUAGE_STATE)
  const brand = useEoValue(BRAND_STATE)
  const firm = useEoValue(FIRM_SELECTOR)
  const isOnMarketplacePage = useEoValue(IS_ON_MARKETPLACE_PAGE_SELECTOR)

  if (isOnMarketplacePage) {
    return <>{`${t('profile.receiveNewsletter')} ${brand?.name[language]}`}</>
  }

  return <>{`${t('profile.receiveNewsletter')} ${firm?.name[language]}`}</>
}

export default NewsLetterSwitchLabel
